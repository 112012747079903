import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Appointment,
} from "../../../app/slices/appointmentSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectPatient } from "../../../components/Selectors/SelectPatient";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { SelectOperationType } from "../../../components/Selectors/SelectOperationType";
import { SelectDoctor } from "../../../components/Selectors/SelectDoctor";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppointmentForm = (props) => {
  const _Appointment = useSelector((state) => state.appointment.appointment);
  const _SearchBody = useSelector((state) => state.appointment.searchBody);
  const _Patient = useSelector((state) => state.patient.patients.data);
  const _OperationType = useSelector(
    (state) => state.operationType.operationTypes.data
  );
  const _User = useSelector((state) => state.user.doctorList);

  const isAdd = _Appointment.form.id ? false : true;
  const { control, reset, watch, handleSubmit, setValue } = useForm({
    defaultValues: _Appointment.form,
  });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        Appointment.create(
          {
            user_id: data.user_id,
            operation_type_id: data.operation_type_id ?? "",
            patient_id: data.patient_id ?? props.patientId,
            date: data.date,
            status: data.status,
            note: data.note,
          },
          _SearchBody,
          props.noLoading
        )
      );
    } else {
      dispatch(
        Appointment.update(
          data.id,
          {
            user_id: data.user_id,
            operation_type_id: data.operation_type_id ?? "",
            patient_id: data.patient_id ?? props.patientId,
            date: data.date,
            status: data.status,
            note: data.note,
          },
          _SearchBody,
          props.noLoading
        )
      );
    }
  };
  useEffect(() => {
    reset({ ..._Appointment.form });
  }, [reset, _Appointment.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setDialog());
  };

  const status = [
    { name: "تم الحجز", key: "تم الحجز" },
    { name: "جاري العمل", key: "جاري العمل" },
    { name: "تم الدفع", key: "تم الدفع" },
    { name: "الغاء", key: "الغاء" },
  ];

  return (
    <Dialog
      open={_Appointment.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="user_id"
                control={control}
                render={({ field }) => (
                  <SelectDoctor
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("user_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _User?.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="operation_type_id"
                control={control}
                render={({ field }) => (
                  <SelectOperationType
                    {...field}
                    onChange={(e, newValue) => {
                      setValue(
                        "operation_type_id",
                        newValue ? newValue.id : null
                      );
                    }}
                    value={
                      _OperationType?.filter((x) => x.id === field.value)[0] ??
                      null
                    }
                  />
                )}
              />
            </Grid>
            {props.patientId ? null : (
              <Grid item xs={12} md={12}>
                <Controller
                  name="patient_id"
                  control={control}
                  render={({ field }) => (
                    <SelectPatient
                      {...field}
                      required
                      onChange={(e, newValue) => {
                        setValue("patient_id", newValue ? newValue.id : null);
                      }}
                      value={
                        _Patient?.filter((x) => x.id === field.value)[0]
                          ? _Patient?.filter((x) => x.id === field.value)[0]
                          : null
                      }
                      search={watch("patient_name")}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={status}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="الحالة" />
                    )}
                    onChange={(event, value) => {
                      setValue("status", value ? value.key : 0);
                    }}
                    value={
                      status.filter((x) => x.key === field.value)[0]
                        ? status.filter((x) => x.key === field.value)[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      inputFormat="YYYY-MM-DD HH:mm"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD HH:mm"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentForm;
