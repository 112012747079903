import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Appointment,
  setSearchBody,
} from "../../../../app/slices/appointmentSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import dayjs from "dayjs";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { setOperationDialog } from "../../../../app/slices/operationSlice";
import OperationForm from "../../../operation/form";
import { GiOfficeChair } from "react-icons/gi";
import { useTheme } from "@emotion/react";
import { HiMenu } from "react-icons/hi";
import { SelectDoctor } from "../../../../components/Selectors/SelectDoctor";

const AppointmentTable = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("lg"));

  const _Role = useSelector((state) => state.user.userInfo.role);
  const _Appointment = useSelector((state) => state.appointment.appointments);
  const _SearchBody = useSelector((state) => state.appointment.searchBody);
  const loading = useSelector((state) => state.appointment.loading);
  const _Operation = useSelector((state) => state.operation.operation);
  const _User = useSelector((state) => state.user.doctorList);

  const [search, setSearch] = useState({
    search: "",
    user_id: null,
    start_date: dayjs().format("YYYY-MM-DD 00:00:00"),
    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Appointment.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "رقم الموعد",
      flex: 0.7,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "user_name",
      headerName: "اسم الطبيب",
      flex: 1,
    },
    {
      field: "status",
      headerName: "حالة الموعد",
      flex: 1,
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1.3,
      renderCell: (params) => {
        return (
          <Typography variant="caption">
            {dayjs(params.row.date).format("YYYY-MM-DD / hh:mm a")}
          </Typography>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: matchUpMd ? 1 : 0.7,
      renderCell: (params) => {
        return matchUpMd ? (
          <>
            <Tooltip title="اضافة جلسة">
              <IconButton
                onClick={() => {
                  dispatch(setOperationDialog());
                  setAppointmentId(params.row.id);
                }}
              >
                <GiOfficeChair />
              </IconButton>
            </Tooltip>
            {_Role === "dentist" ? null : (
              <Tooltip title="تعديل">
                <IconButton
                  color="primary"
                  onClick={() => dispatch(Appointment.getById(params.row.id))}
                >
                  <BiEdit />
                </IconButton>
              </Tooltip>
            )}
            {_Role === "dentist" ? null : (
              <Tooltip title="حذف">
                <IconButton
                  color="error"
                  onClick={() => handleDeleteClick(params.row)}
                >
                  <BiTrash />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <IconButton
            onClick={(e) => handleMenuClick(e, params)}
            aria-controls={openMenu ? params.row.id : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <HiMenu />
          </IconButton>
        );
      },
    },
  ];

  const operationMenu = (params) => (
    <Menu
      sx={{ direction: "ltr" }}
      anchorEl={openMenu}
      keepMountede
      id="account-menu"
      open={activeItem === params}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          dispatch(setOperationDialog());
          setAppointmentId(params.row.id);
        }}
        color="success"
      >
        <ListItemIcon>
          <GiOfficeChair size={20} color="green" />
        </ListItemIcon>
        اضافة جلسة
      </MenuItem>
      {_Role === "dentist" ? null : (
        <MenuItem
          onClick={() => {
            dispatch(Appointment.getById(params.row.id));
          }}
        >
          <ListItemIcon>
            <BiEdit size={20} color="#0288D1" />
          </ListItemIcon>
          تعديل
        </MenuItem>
      )}
      {_Role === "dentist" ? null : (
        <MenuItem
          onClick={() => {
            handleDeleteClick(params.row);
          }}
        >
          <ListItemIcon>
            <BiTrash size={20} color="#D32F2F" />
          </ListItemIcon>
          حذف
        </MenuItem>
      )}
    </Menu>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Appointment.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        user_id: search.user_id ?? "",
        start_date: search.start_date,
        end_date: search.end_date,
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch, search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_Operation.dialog && <OperationForm appointmentId={appointmentId} />}
          {activeItem && operationMenu(activeItem)}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.patient_name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={6} md={3}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              value={search.search}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <SelectDoctor
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  user_id: newValue ? newValue.id : null,
                });
              }}
              value={_User?.filter((x) => x.id === search.user_id)[0] ?? null}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    start_date: dayjs().format("YYYY-MM-DD 00:00:00"),
                    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
                    user_id: null,
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Appointment.data ? (
                <DataGrid
                  rows={_Appointment.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Appointment.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentTable;
