import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Reports, setSearchBody } from "../../../../../app/slices/reportsSlice";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";

const XrayTable = () => {
  const [search, setSearch] = useState({
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const _Reports = useSelector((state) => state.reports.xray);
  const loading = useSelector((state) => state.reports.loading);
  const _SearchBody = useSelector((state) => state.reports.searchBody);
  const reportTypes = useSelector((state) => state.reports.reportTypes);

  const columns = [
    {
      field: "id",
      headerName: "رقم الاشعة",
      flex: 1,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "xray_type_name",
      headerName: "نوع الاشعة",
      flex: 1,
    },
    {
      field: "price",
      headerName: "السعر",
      flex: 1,
      renderCell: (params) => params.row.price?.toLocaleString(),
    },
    {
      field: "user_name",
      headerName: "اسم الطبيب",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return <>{dayjs(params.row.date).format("YYYY-MM-DD / hh:mm: a")}</>;
      },
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 1,
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Reports.xray(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        start_date: search.start_date,
        end_date: search.end_date,
      })
    );
  }, [dispatch, search, reportTypes]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير الاشعة | مركز اوبين لطب الاسنان والتجميل",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    start_date: dayjs().format("YYYY-MM-01"),
                    end_date: dayjs().format("YYYY-MM-DD"),
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Reports.data}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Reports.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="client"
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Toolbar: customToolbar,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default XrayTable;
