import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooth } from "../../../../app/slices/toothSlice";
import { Controller, useForm } from "react-hook-form";
import {
  TreatmentPlan,
  resetForm,
  resetTeethGrid,
} from "../../../../app/slices/treatmentPlanSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { setPatientId } from "../../../../app/slices/operationSlice";

const TreatmentPlanForm = () => {
  const { patient_id } = useParams();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState({});
  const [operations, setOperations] = useState([]);

  const _TreatmentPlan = useSelector(
    (state) => state.treatmentPlan.treatmentPlan
  );
  const _HandleOperationType = useSelector(
    (state) => state.treatmentPlan.handleOperationTypes
  );
  const _SearchBody = useSelector((state) => state.treatmentPlan.searchBody);
  const loading = useSelector((state) => state.treatmentPlan.loading);

  const isAdd = _TreatmentPlan.form?.id ? false : true;
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: _TreatmentPlan.form,
  });

  useEffect(() => {
    dispatch(setPatientId(patient_id));
  }, []);

  useEffect(() => {
    setOperations(_TreatmentPlan?.form.treatment_plan_operation);
  }, [_TreatmentPlan]);

  useEffect(() => {
    setSelectedType(
      _TreatmentPlan?.form.treatment_plan_operation.reduce((acc, x) => {
        return { ...acc, [x.operation_type_id]: true };
      }, {})
    );
  }, [_TreatmentPlan]);

  useEffect(() => {
    if (patient_id !== "add") {
      dispatch(TreatmentPlan.getAllOperationTypes()).then((x) => {
        dispatch(
          TreatmentPlan.getAll({
            patient_id: patient_id,
            skip: 0,
            take: 1,
          })
        );
      });
    } else {
      dispatch(TreatmentPlan.getAllOperationTypes());
      dispatch(resetForm());
    }
  }, [patient_id]);

  useEffect(() => {
    dispatch(Tooth.getAll());
  }, []);

  useEffect(() => {
    reset({ ..._TreatmentPlan.form });
  }, [reset, _TreatmentPlan.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetTeethGrid());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   setValue(
  //     "operations",
  //     _HandleOperationType.filter((instance) => instance.teeth.length > 0)
  //   );
  // }, [_HandleOperationType]);

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        TreatmentPlan.create(
          {
            patient_id: patient_id,
            date: data.date,
            note: data.note,
            operations: operations,
          },
          _SearchBody
        )
      ).then((x) => {
        // dispatch(TreatmentPlan.getAllOperationTypes());
        // dispatch(resetForm());
      });
    } else {
      dispatch(
        TreatmentPlan.update(
          data.id,
          {
            patient_id: data.patient_id,
            date: data.date,
            note: data.note,
            operations: operations,
          },
          _SearchBody
        )
      ).then((x) => {
        // dispatch(TreatmentPlan.getAllOperationTypes());
        // dispatch(resetForm());
      });
    }
  };

  return (
    <Card>
      {loading && <LinearProgress />}
      <Box sx={{ width: "100%", padding: 2, marginBottom: 10 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Grid container spacing={2}>
                {types.map((name, index) => (
                  <Grid item xs={2.4} key={index}>
                    <Button
                      size="small"
                      fullWidth
                      sx={{
                        minWidth: 0,
                        height: 150,
                      }}
                      variant={
                        selectedType === index ? "contained" : "outlined"
                      }
                      onClick={(e) => {
                        setSelectedType(index);
                      }}
                    >
                      {name.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid> */}

            <Grid item xs={12} md={3}>
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="التاريخ"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "يوم/شهر/سنة",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>

            <Grid item xs={3}>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(TreatmentPlan.getAllOperationTypes());
                  dispatch(resetForm());
                  setOperations([]);
                  setSelectedType([]);
                }}
              >
                تفريغ الحقول
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {_HandleOperationType.map(
              (type, index) =>
                type.is_treatment_plan && (
                  <Grid item xs={12} md={12} key={index}>
                    <Stack direction="row" spacing={2}>
                      <FormControlLabel
                        sx={{ width: "15%" }}
                        control={
                          <Checkbox
                            checked={selectedType[type.id] ? true : false}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              setSelectedType((prevTypes) => ({
                                ...prevTypes,
                                [type.id]: newValue,
                              }));

                              setOperations((prevOperations) => {
                                if (newValue) {
                                  return [
                                    ...prevOperations,
                                    {
                                      operation_type_id: type.id,
                                      type: "",
                                      count: "",
                                    },
                                  ];
                                } else {
                                  return prevOperations.filter(
                                    (op) => op.operation_type_id !== type.id
                                  );
                                }
                              });
                            }}
                          />
                        }
                        label={type.operation_type_name}
                      />
                      <Controller
                        name="type"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            disabled={selectedType[type.id] ? false : true}
                            size="small"
                            label="النوع"
                            value={
                              operations.find(
                                (op) => op.operation_type_id === type.id
                              )?.type || ""
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setOperations((prevOperations) =>
                                prevOperations.map((op) =>
                                  op.operation_type_id === type.id
                                    ? { ...op, type: newValue }
                                    : op
                                )
                              );
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="count"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            disabled={selectedType[type.id] ? false : true}
                            size="small"
                            label="العدد"
                            value={
                              operations.find(
                                (op) => op.operation_type_id === type.id
                              )?.count || ""
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setOperations((prevOperations) =>
                                prevOperations.map((op) =>
                                  op.operation_type_id === type.id
                                    ? { ...op, count: newValue }
                                    : op
                                )
                              );
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                )
            )}

            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};

export default TreatmentPlanForm;
