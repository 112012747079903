import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setOperationDialog,
  resetForm,
  OperationType,
} from "../../../app/slices/operationTypeSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OperationTypeForm = () => {
  const _OperationType = useSelector(
    (state) => state.operationType.operationType
  );
  const _SearchBody = useSelector((state) => state.operationType.searchBody);

  const isAdd = _OperationType.form.id ? false : true;
  const { control, reset, handleSubmit, setValue } = useForm({
    defaultValues: _OperationType.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        OperationType.create(
          {
            name: data.name,
            section_id: data.section_id,
            note: data.note,
            discount: data.discount ? data.discount : 0,
            deduction: data.deduction ? data.deduction : 0,
            price: data.price,
            is_treatment_plan: data.is_treatment_plan,
          },
          _SearchBody
        )
      );
    } else {
      dispatch(
        OperationType.update(
          data.id,
          {
            id: data.id,
            name: data.name,
            section_id: data.section_id,
            note: data.note,
            discount: data.discount ? data.discount : 0,
            deduction: data.deduction ? data.deduction : 0,
            price: data.price,
            is_treatment_plan: data.is_treatment_plan,
          },
          _SearchBody
        )
      );
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    reset({ ..._OperationType.form });
  }, [reset, _OperationType.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  const handleDialog = () => {
    dispatch(setOperationDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  return (
    <Dialog
      open={_OperationType.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="السعر"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="discount"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="الخصم"
                    fullWidth
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="deduction"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="تكاليف الخدمة"
                    fullWidth
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="is_treatment_plan"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_treatment_plan", e.target.checked);
                        }}
                      />
                    }
                    label="إضافة لخطة العلاج؟"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default OperationTypeForm;
