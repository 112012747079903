import { createSlice } from "@reduxjs/toolkit";
import { showNotification } from "./notificationSlice";
import { factory } from "../../api/apiFactory";
import dayjs from "dayjs";
const waitingListApi = factory.get("waitingList");
const initialState = {
  loading: false,
  searchBody: {
    date: dayjs().format("YYYY-MM-DD"),
  },
  waitingList: {
    dialog: false,
    form: {
      id: null,
      current_status: null,
      patient_id: null,
      start_date: null,
      finish_date: null,
    },
  },
  waitingListData: {
    data: [],
    checkingData: [],
    xRayData: [],
    total: 0,
    newWaitingData: {},
  },
  status: [
    {
      nameEn: "Waiting Treatment",
      nameAr: "انتظار الفحص",
      key: "waiting_treatment",
    },
    { nameEn: "In Treatment", nameAr: "داخل الفحص", key: "in_treatment" },
    // { nameEn: "Waiting Xray", nameAr: "انتظار الاشعة", key: "waiting_xray" },
    // { nameEn: "In Xray", nameAr: "داخل الاشعة", key: "in_xray" },
    { nameEn: "Leaving", nameAr: "المغادرة", key: "leaving" },
  ],
  waitingListTypes: [],
  timeData: {
    dialog: false,
    data: {},
  },
};

export const waitingListSlice = createSlice({
  name: "waitingList",
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.waitingList.dialog = !state.waitingList.dialog;
    },
    setTimesDialog: (state, action) => {
      state.timeData.dialog = !state.timeData.dialog;
    },
    setDataTable: (state, { payload }) => {
      function convertPayloadToPatients(payload) {
        const flattenedPayload = payload.in_treatment
          .concat(payload.in_xray)
          .concat(payload.leaving)
          .concat(payload.waiting_treatment)
          .concat(payload.waiting_xray);
        const patients = {};

        for (const patient of flattenedPayload) {
          patients[`patient-${patient.id}`] = {
            patient_id: `patient-${patient.id}`,
            patient_name: patient.patient_name,
            current_status: patient.current_status,
            id: patient.patient_id,
            created_at: patient.date_time,
            in_treatment: patient.in_treatment,
            in_xray: patient.in_xray,
            leaving: patient.leaving,
            waiting_xray: patient.waiting_xray,
            waiting_treatment: patient.waiting_treatment,
          };
        }

        return patients;
      }
      state.waitingListData.data = {
        patients: convertPayloadToPatients(payload.data.data[0]),
        columnOrder: [
          "column-1",
          "column-2",
          // "column-3",
          // "column-4",
          "column-5",
        ],
        columns: {
          "column-1": {
            id: "column-1",
            title: "انتظار الفحص",
            patient_id: payload.data.data
              .map((x) => x.waiting_treatment)[0]
              ?.map((x) => `patient-${x.id}`),
            waiting_id: payload.data.data
              .map((x) => x.waiting_treatment)[0]
              ?.map((x) => `patient-${x.patient_id}`),
          },
          "column-2": {
            id: "column-2",
            title: "داخل غرفة الفحص",
            patient_id: payload.data.data
              .map((x) => x.in_treatment)[0]
              ?.map((x) => `patient-${x.id}`),
            waiting_id: payload.data.data
              .map((x) => x.waiting_treatment)[0]
              ?.map((x) => `patient-${x.patient_id}`),
          },
          // "column-3": {
          //   id: "column-3",
          //   title: "انتظار الاشعة",
          //   patient_id: payload.data.data
          //     .map((x) => x.waiting_xray)[0]
          //     ?.map((x) => `patient-${x.id}`),
          //   waiting_id: payload.data.data
          //     .map((x) => x.waiting_treatment)[0]
          //     ?.map((x) => `patient-${x.patient_id}`),
          // },
          // "column-4": {
          //   id: "column-4",
          //   title: "داخل غرفة الاشعة",
          //   patient_id: payload.data.data
          //     .map((x) => x.in_xray)[0]
          //     ?.map((x) => `patient-${x.id}`),
          //   waiting_id: payload.data.data
          //     .map((x) => x.waiting_treatment)[0]
          //     ?.map((x) => `patient-${x.patient_id}`),
          // },
          "column-5": {
            id: "column-5",
            title: "مغادرة",
            patient_id: payload.data.data
              .map((x) => x.leaving)[0]
              ?.map((x) => `patient-${x.id}`),
            waiting_id: payload.data.data
              .map((x) => x.waiting_treatment)[0]
              ?.map((x) => `patient-${x.patient_id}`),
          },
        },
      };

      state.waitingListData.total = payload.data.total;
    },
    setWaitingListData: (state, { payload }) => {
      state.waitingListTypes = payload.data.data;
    },
    setById: (state, { payload }) => {
      state.waitingList.form = payload;
    },
    setTimesData: (state, { payload }) => {
      state.timeData.data = payload;
    },
    setByIdForUpdate: (state, { payload }) => {
      state.waitingList.form = payload;
    },
    setLoading: (state, action) => {
      state.loading = !state.loading;
    },
    resetForm: (state, action) => {
      state.waitingList.form = initialState.waitingList.form;
    },
    setSearchBody: (state, { payload }) => {
      state.searchBody = payload;
    },
  },
});

export const {
  setLoading,
  setDialog,
  setById,
  setDataTable,
  resetForm,
  setSearchBody,
  setWaitingListData,
  setByIdForUpdate,
  setTimesDialog,
  setTimesData
} = waitingListSlice.actions;

export default waitingListSlice.reducer;

//axios
const getAll = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("waitingList"));
    const res = await waitingListApi.getAll(params);
    dispatch(setDataTable(res));
    dispatch(setLoading("waitingList"));
  } catch (err) {
    dispatch(setLoading("waitingList"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getWaitingListTypes = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("waitingList"));
    const res = await waitingListApi.getWaitingTypes(params);
    dispatch(setWaitingListData(res));
    dispatch(setLoading("waitingList"));
  } catch (err) {
    dispatch(setLoading("waitingList"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const deleteById = (id, _SearchBody) => async (dispatch) => {
  try {
    await waitingListApi.delete(id);
    dispatch(
      showNotification({
        message: "تم الحذف بنجاح",
        type: "success",
      })
    );
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getByIdForUpdate = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("waitingList"));
    const res = await waitingListApi.getById(id);
    dispatch(setByIdForUpdate(res.data.data[0]));

    dispatch(setDialog());
    dispatch(setLoading("waitingList"));
  } catch (err) {
    dispatch(setLoading("waitingList"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const getById = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading("waitingList"));
    // const res = await waitingListApi.getById(id);
    dispatch(setById(payload));
    dispatch(setDialog());
    dispatch(setLoading("waitingList"));
  } catch (err) {
    dispatch(setLoading("waitingList"));
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};
const create =
  (data, searchBody, patientId, isOpenDialog) => async (dispatch) => {
    try {
      await waitingListApi.create(data);
      dispatch(
        showNotification({
          message: "تمت الاضافة  الى قائمة الانتظار بنجاح",
          type: "success",
        })
      );
      if (!isOpenDialog) {
        dispatch(setDialog());
      }
      if (!patientId) {
        dispatch(getAll(searchBody));
      }
    } catch (err) {
      dispatch(
        showNotification({
          message: err.response.data.errors[0],
          type: "error",
        })
      );
      throw new Error(err);
    }
  };
const update = (id, data, _SearchBody, isOpenDialog) => async (dispatch) => {
  try {
    await waitingListApi.update(id, data);
    dispatch(
      showNotification({
        message: "تم التعديل  بنجاح",
        type: "success",
      })
    );
    if (isOpenDialog) {
      dispatch(setDialog());
    }
    dispatch(getAll(_SearchBody));
  } catch (err) {
    dispatch(
      showNotification({
        message: err.response.data.errors[0],
        type: "error",
      })
    );
    throw new Error(err);
  }
};

export const waitingList = {
  getAll,
  deleteById,
  getById,
  create,
  update,
  getWaitingListTypes,
  getByIdForUpdate,
};
