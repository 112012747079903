import React, { forwardRef, useRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { Box } from "@mui/system";
import { useEffect } from "react";
import Logo from "../../../../../assets/Logo";
import { useDispatch, useSelector } from "react-redux";
import { setPrescriptionDialog } from "../../../../../app/slices/operationSlice";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GeneratePrescription({ data = {}, print }) {
  const _Operation = useSelector((state) => state.operation.prescription);
  const _Patient = useSelector((state) => state.patient.patient.form);

  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
    body {
      direction: rtl; 
      }`,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    // handlePrint();
  }, []);

  const handleDialog = () => {
    dispatch(setPrescriptionDialog(null));
  };

  return (
    <Dialog
      open={_Operation.dialog}
      TransitionComponent={Transition}
      keepMounted
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"الوصفة الطبية"}
        </Typography>
        <div>
          <Button
            onClick={() => {
              handlePrint();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            طباعة
          </Button>
          <div ref={printRef} style={{ padding: "15px" }}>
            <Box
              sx={{
                position: "relative",
                height: "180mm",
                width: "80mm ",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} display={"flex"} alignItems={"center"}>
                  <Typography variant="h4">الوصفة الطبية</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Logo vertical style={{ width: "150px", float: "left" }} />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={7.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>اسم المراجع:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.patient_name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>التاريخ:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {dayjs(_Operation.data?.date).format("YYYY-MM-DD")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={7.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>العمر:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Patient?.age} سنة
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>الجنس:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Patient?.gender}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={7.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>اسم الطبيب:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.user}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4.5}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>رقم الجلسة:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.id}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Typography fontSize={11}>نوع الجلسة:</Typography>
                    <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                      {_Operation.data?.operation_type_name}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontSize={11}>الوصفة:</Typography>
                  <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                    {_Operation.data.prescription
                      ?.split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          {index <
                            _Operation.data.prescription?.split("\n").length -
                            1 && <br />}
                        </React.Fragment>
                      ))}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 3 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 6 }}></Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={1} alignItems={"start"}>
                      <Typography fontSize={11}>العنوان:</Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        بغداد - زيونة - شارع الربيعي - مقابيل ماكسي مول -
                        البناية الذهبية - الطابق الثالث{" "}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography fontSize={11}>هاتف:</Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        07731130005
                      </Typography>
                      <Typography fontSize={12} sx={{ fontWeight: "bold" }}>
                        - 07805550444
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
