import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  Autocomplete,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  Patient,
} from "../../../app/slices/patientSlice";
import { Controller, useForm } from "react-hook-form";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SelectCity } from "../../../components/Selectors/SelectCity";
import { SelectProvince } from "../../../components/Selectors/SelectProvince";
import dayjs from "dayjs";
import { SelectKnownUs } from "../../../components/Selectors/SelectKnownUs";
import { MdLocationOn } from "react-icons/md";
import { setDialog as cityDialog, setCityTable } from "../../../app/slices/provincesSlice";
import CityForm from "../../management/provinces/cities/form";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PatientForm = () => {
  const _Patient = useSelector((state) => state.patient.patient);
  const _SearchBody = useSelector((state) => state.patient.searchBody);
  const _Cities = useSelector((state) => state.provinces.cities);
  const _provinces = useSelector((state) => state.provinces.provinces);
  const _KnownUs = useSelector((state) => state.patient.knownUs);
  const _City = useSelector((state) => state.provinces.cities);

  const isAdd = _Patient.form.id ? false : true;
  const { control, reset, watch,  handleSubmit, setValue } = useForm({
    defaultValues: _Patient.form,
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(Patient.create({
        first_name: data.first_name,
        full_name: data.full_name,
        gender: data.gender,
        how_know_us: data.how_know_us,
        address: data.address,
        city_id: data.city_id,
        birth_date: data.birth_date,
        mobile_1: data.mobile_1,
        mobile_2: data.mobile_2,
        note: data.note,
        province_id: data.province_id,
      }));
    } else {
      dispatch(Patient.update(data.id, {
        id: data.id,
        first_name: data.first_name,
        full_name: data.full_name,
        gender: data.gender,
        how_know_us: data.how_know_us,
        address: data.address,
        city_id: data.city_id,
        birth_date: data.birth_date,
        mobile_1: data.mobile_1,
        mobile_2: data.mobile_2,
        note: data.note,
        province_id: data.province_id,
      }, _SearchBody));
    }
  };
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(Patient.KnownUs())
  }, [dispatch]);

  useEffect(() => {
    reset({ ..._Patient.form });
  }, [reset,_Patient.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);


  const handleDialog = () => {
    dispatch(setDialog());
  };

  const gender = [
    { id: 'ذكر', name: `ذكر` },
    { id: 'انثى', name: `انثى` },
  ];

  useEffect(() => {
    const selectedCities = _provinces.data?.filter((x) => x.id === watch('province_id') ? x.city : null)[0]
    dispatch(setCityTable(selectedCities?.city));
  }, [dispatch,_provinces.data, watch])

  return (
    <Dialog
      open={_Patient.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      {_City.dialog ? <CityForm provinceId={watch('province_id')} /> : null}
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم الاول"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="full_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم الكامل"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="birth_date"
                control={control}
                render={({ field }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="YYYY-MM-DD"
                      label="تاريخ الميلاد"
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("birth_date", dayjs(e).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          inputProps={{ ...params.inputProps, placeholder: "يوم/شهر/سنة" }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={gender}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    size="small"
                    renderInput={(params) => (
                      <TextField required {...params} label="الجنس" />
                    )}
                    onChange={(event, value) => {
                      setValue('gender', value ? value.id : 0);
                    }}
                    value={
                      gender.filter((x) => x.id === field.value)[0]
                        ? gender.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="province_id"
                control={control}
                render={({ field }) => (
                  <SelectProvince
                    required
                    povinceid={field.value}
                    onChange={(e, newValue) => {
                      setValue("province_id", newValue ? newValue.id : null);
                      setValue("city_id", null);
                    }}
                    value={
                      _provinces.data?.filter((x) => x.id === field.value)[0]
                        ? _provinces.data?.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="city_id"
                control={control}
                render={({ field }) => (
                  <SelectCity
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("city_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _Cities.data?.filter((x) => x.id === field.value)[0]
                        ? _Cities.data?.filter(
                          (x) => x.id === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="add_city"
                control={control}
                render={({ field }) => (
                  <Button
                    disabled={watch('province_id') ? false : true}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<MdLocationOn />}
                    onClick={() => dispatch(cityDialog())}
                  >
                    أضافة مدينة جديدة
                  </Button>
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="اقرب نقطة دالة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="mobile_1"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="رقم الهاتف الاول"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="mobile_2"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="رقم الهاتف الثاني"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="how_know_us"
                control={control}
                render={({ field }) => (
                  <SelectKnownUs
                    onChange={(e, newValue) => {
                      setValue("how_know_us", newValue ? newValue : null);
                    }}
                    value={
                      _KnownUs?.filter((x) => x === field.value)[0]
                        ? _KnownUs?.filter(
                          (x) => x === field.value
                        )[0]
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={3}
                    size="small"
                    label="الملاحظات"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PatientForm;
