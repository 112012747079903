import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Reports, setSearchBody } from "../../../../../app/slices/reportsSlice";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { SelectExpenseTypes } from "../../../../../components/Selectors/SelectExpensType";

const SpendingTable = () => {
  const [search, setSearch] = useState({
    spending_type_id: "",
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const _Reports = useSelector((state) => state.reports.spending);
  const loading = useSelector((state) => state.reports.loading);
  const _SearchBody = useSelector((state) => state.reports.searchBody);
  const _ExpenseTypes = useSelector((state) => state.expenseTypes.expensesTypes.data);
  const reportTypes = useSelector((state) => state.reports.reportTypes)

  const columns = [
    {
      field: "spending_type_name",
      headerName: "نوع المصاريف",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => (
        <>{parseInt(params.row.amount)?.toLocaleString()}</>
      )
    },
    {
      field: "note",
      headerName: "ملاحظة",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD")
    },
    // {
    //   field: "operations",
    //   headerName: "العمليات",
    //   flex: 0.7,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <IconButton
    //           color="primary"
    //           onClick={() => dispatch(Reports.getById(params.row.id))}
    //         >
    //           <BiEdit />
    //         </IconButton>

    //         <IconButton
    //           color="error"
    //           onClick={() => handleDeleteClick(params.row)}
    //         >
    //           <BiTrash />
    //         </IconButton>
    //       </>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Reports.spending(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(setSearchBody({
      spending_type_id: search.spending_type_id ?? "",
      start_date: search.start_date,
      end_date: search.end_date,
    }))
  }, [dispatch, search, reportTypes]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير المصاريف | مركز اوبين لطب الاسنان والتجميل",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  )

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={4}>
            <SelectExpenseTypes
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  spending_type_id: newValue ? newValue.id : null
                });
              }}
              value={
                _ExpenseTypes?.filter((x) => x.id === search.spending_type_id)[0]
                  ? _ExpenseTypes?.filter(
                    (x) => x.id === search.spending_type_id
                  )[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    spending_type_id: "",
                    start_date: dayjs().format("YYYY-MM-01"),
                    end_date: dayjs().format("YYYY-MM-DD"),
                  });
                  setPageSize(15)
                  setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Reports.data ? (
                <DataGrid
                  rows={_Reports.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Reports.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="client"
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage)
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: customToolbar
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SpendingTable;
