import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  TextField,
  Stack,
  Typography,
  useMediaQuery,
  ListItemIcon,
  MenuItem,
  Menu,
  ToggleButtonGroup,
  ToggleButton,
  SpeedDial,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Operation,
  setMultiOpertaionReceiptData,
  setMultiOpertaionReceiptDialog,
  setSearchBody,
} from "../../../../app/slices/operationSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import { AiFillDollarCircle, AiOutlineProfile } from "react-icons/ai";
import { FaPrescriptionBottleAlt, FaReceipt } from "react-icons/fa";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import { setOperationPaymentDialog } from "../../../../app/slices/paymentsSlice";
import OperationPayments from "../payments";
import GenerateReceipt from "../../receipt";
import { SelectOperationType } from "../../../../components/Selectors/SelectOperationType";
import { useTheme } from "@emotion/react";
import { HiMenu } from "react-icons/hi";
import GeneratePrescription from "../../../tooth/tabs/operation/printPrescription";
import GenerateMultiOperationReceipt from "../../receipt/multiOperation";
import { Patient } from "../../../../app/slices/patientSlice";
import { useNavigate } from "react-router";

const OperationTable = () => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("lg"));

  const navigate = useNavigate();

  const _Role = useSelector((state) => state.user.userInfo.role);
  const _Operation = useSelector((state) => state.operation.operations);
  const _SearchBody = useSelector((state) => state.operation.searchBody);
  const loading = useSelector((state) => state.operation.loading);
  const _OperationType = useSelector(
    (state) => state.operationType.operationTypes.data
  );
  const _OperationPayment = useSelector(
    (state) => state.payment.operationPayment.dialog
  );
  const _OperationReceipt = useSelector(
    (state) => state.operation.receipt.dialog
  );
  const _OperationPrescription = useSelector(
    (state) => state.operation.prescription.dialog
  );
  const _MultiOpertaionReceipt = useSelector(
    (state) => state.operation.multiOpertaionReceipt.dialog
  );
  const [search, setSearch] = useState({
    search: "",
    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
    operation_type_id: null,
    has_debt: false,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [isDept, setIsDept] = useState("all");
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMultiSelect, setIsMultiSelect] = useState(false);

  const handleChange = (event, newType) => {
    setIsDept(newType);
  };

  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Operation.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "رقم الجلسة",
      flex: 0.5,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography>{params.row.patient_name}</Typography>
          <Typography>{params.row.patient_mobile_1}</Typography>
        </Stack>
      ),
    },
    {
      field: "user",
      headerName: "اسم الطبيب",
      flex: 1,
    },
    {
      field: "operation_type_name",
      headerName: "نوع الجلسة",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ الكلي (IQD)",
      flex: 1,
      renderCell: (params) => (
        <>{parseInt(params.row.amount)?.toLocaleString()}</>
      ),
    },
    {
      field: "paid",
      headerName: "المبلغ المدفوع (IQD)",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography color="green">
            {parseInt(params.row.paid)?.toLocaleString()}
          </Typography>
          <Typography color="error">
            {parseInt(params.row.debt)?.toLocaleString()}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "discount",
      headerName: "الخصم (IQD)",
      flex: 0.5,
      renderCell: (params) => (
        <>{parseInt(params.row.discount)?.toLocaleString()}</>
      ),
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 0.7,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: matchUpMd ? 1.5 : 0.7,
      renderCell: (params) => {
        return matchUpMd ? (
          <>
            <Tooltip title="الملف الطبي">
              <IconButton
                onClick={() => {
                  navigate("/app/medical_profile/" + params.row.patient_id);
                }}
              >
                <AiOutlineProfile />
              </IconButton>
            </Tooltip>
            <Tooltip title="المدفوعات">
              <IconButton
                color="success"
                onClick={() =>
                  dispatch(setOperationPaymentDialog(params.row.id))
                }
              >
                <AiFillDollarCircle />
              </IconButton>
            </Tooltip>
            <Tooltip title="وصل استلام">
              <IconButton
                color="info"
                onClick={() =>
                  dispatch(Operation.getByIdForReceipt(params.row.id))
                }
              >
                <FaReceipt />
              </IconButton>
            </Tooltip>
            <Tooltip title="الوصفة الطبية">
              <IconButton
                onClick={() =>
                  dispatch(Operation.getByIdForPrescription(params.row.id))
                }
              >
                <FaPrescriptionBottleAlt />
              </IconButton>
            </Tooltip>
            {_Role === "dentist" ? null : (
              <Tooltip title="حذف">
                <IconButton
                  color="error"
                  onClick={() => handleDeleteClick(params.row)}
                >
                  <BiTrash />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <IconButton
            onClick={(e) => handleMenuClick(e, params)}
            aria-controls={openMenu ? params.row.id : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <HiMenu />
          </IconButton>
        );
      },
    },
  ];

  const operationMenu = (params) => (
    <Menu
      sx={{ direction: "ltr" }}
      anchorEl={openMenu}
      keepMountede
      id="account-menu"
      open={activeItem === params}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => {
          navigate("/app/medical_profile/" + params.row.patient_id);
        }}
        color="success"
      >
        <ListItemIcon>
          <AiOutlineProfile size={20} color="green" />
        </ListItemIcon>
        الملف الطبي
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(setOperationPaymentDialog(params.row.id));
        }}
        color="success"
      >
        <ListItemIcon>
          <AiFillDollarCircle size={20} color="green" />
        </ListItemIcon>
        المدفوعات
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(Operation.getByIdForReceipt(params.row.id));
        }}
      >
        <ListItemIcon>
          <FaReceipt size={20} color="#0288D1" />
        </ListItemIcon>
        وصل استلام
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(Operation.getByIdForPrescription(params.row.id));
        }}
      >
        <ListItemIcon>
          <FaPrescriptionBottleAlt size={20} />
        </ListItemIcon>
        الوصفة الطبية
      </MenuItem>
      {_Role === "dentist" ? null : (
        <MenuItem
          onClick={() => {
            handleDeleteClick(params.row);
          }}
        >
          <ListItemIcon>
            <BiTrash size={20} color="#D32F2F" />
          </ListItemIcon>
          حذف
        </MenuItem>
      )}
    </Menu>
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Operation.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        start_date: search.start_date,
        end_date: search.end_date,
        has_debt: search.has_debt ? 1 : 0,
        operation_type_id: search.operation_type_id ?? "",
        search: search.search,
        skip: pageSize * currentPage,
        take: pageSize,
      })
    );
  }, [dispatch, search, pageSize, currentPage]);

  return (
    <Card>
      <CardContent>
        <Box>
          {selectedRows.length > 0 && (
            <SpeedDial
              ariaLabel="SpeedDial basic example"
              sx={{ position: "absolute", bottom: "10%", right: 40 }}
              icon={<FaReceipt size={30} />}
              onClick={() =>
                dispatch(
                  Patient.getByIdForData(selectedRows[0]?.patient_id)
                ).then(() => dispatch(setMultiOpertaionReceiptDialog()))
              }
            ></SpeedDial>
          )}
          {_OperationPayment && <OperationPayments />}
          {_OperationReceipt && <GenerateReceipt />}
          {_OperationPrescription && <GeneratePrescription />}
          {_MultiOpertaionReceipt && <GenerateMultiOperationReceipt />}
          {activeItem && operationMenu(activeItem)}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف الجلسة المرقمة:"
            name={dialogData.id}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={2}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              fullWidth
              value={isDept}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton
                value="all"
                onClick={() =>
                  setSearch({
                    ...search,
                    has_debt: false,
                  })
                }
              >
                الكل
              </ToggleButton>
              <ToggleButton
                value="dept"
                onClick={() =>
                  setSearch({
                    ...search,
                    has_debt: true,
                  })
                }
              >
                الديون
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={2.5}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="اسم او رقم المراجع"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2.5}>
            <SelectOperationType
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  operation_type_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _OperationType?.filter(
                  (x) => x.id === search.operation_type_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD 00:00:00"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD 23:59:59"
                    ),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    start_date: dayjs().format("YYYY-MM-01 00:00:00"),
                    end_date: dayjs().format("YYYY-MM-DD 23:59:59"),
                    // patient_id: null,
                    operation_type_id: null,
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Operation?.data}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Operation.total}
                loading={loading}
                rowHeight={55}
                pagination
                paginationMode="server"
                onPageChange={(newPage) => setCurrentPage(newPage)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
                disableSelectionOnClick
                checkboxSelection
                onSelectionModelChange={(selection) => {
                  const patient_name = selection.map((id) =>
                    _Operation.data?.find((row) => row.id === id)
                  );
                  const selectedRowData = selection.map((id) =>
                    _Operation.data?.find((row) => row.id === id)
                  );
                  setIsMultiSelect(patient_name.length === 0 ? false : true);
                  setSelectedRows(selectedRowData);
                  dispatch(setMultiOpertaionReceiptData(selectedRowData));
                }}
                isRowSelectable={(params) => {
                  const selectedRow = params.row;
                  return isMultiSelect
                    ? selectedRow?.patient_name ===
                        selectedRows[0]?.patient_name
                    : true;
                }}
                selectionModel={selectedRows?.map((row) => row.id)}
                disableMultipleSelection
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OperationTable;
