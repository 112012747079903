import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  InputAdornment,
  Box,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { resetForm, Operation } from "../../../../../app/slices/operationSlice";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { SelectOperationType } from "../../../../../components/Selectors/SelectOperationType";
import { SelectDoctor } from "../../../../../components/Selectors/SelectDoctor";
import { useParams } from "react-router";
import { AiOutlineSave } from "react-icons/ai";
import { BiSolidEraser } from "react-icons/bi";
import { BsFillXCircleFill } from "react-icons/bs";
import FilePondInput from "../../../../../helpers/FilePondInput";

const OperationForm = () => {
  const { patient_id } = useParams();
  const dispatch = useDispatch();

  const _PatientId = useSelector(
    (state) => state.operation.patientId ?? patient_id
  );
  const _Operation = useSelector((state) => state.operation.operation);
  const loading = useSelector((state) => state.operation.createLoading);
  const _SearchBody = useSelector((state) => state.operation.searchBody);
  const _OperationType = useSelector(
    (state) => state.operationType.operationTypes.data
  );
  const _user = useSelector((state) => state.user.doctorList);

  const isAdd = _Operation.form.id ? false : true;
  const { control, reset, watch, handleSubmit, setValue } = useForm({
    defaultValues: _Operation.form,
  });

  const [onRemoveFiles, setOnRemoveFiles] = useState(false);
  const [selected, setSelected] = useState(null);
  const [hoveredColor, setHoveredColor] = useState(false);

  const handleMouseEnter = (index) => {
    setSelected(index);
  };

  const handleMouseLeave = (index) => {
    setSelected(null);
  };

  const onSubmit = (data) => {
    data.patient_id = _PatientId;
    const formData = new FormData();
    const uploadFormData = new FormData();
    if (!isAdd) formData.append("id", data.id);
    if (!isAdd) formData.append("_method", "PUT");
    formData.append("patient_id", _PatientId);
    formData.append("operation_type_id", data.operation_type_id);
    formData.append("user_id", data.user_id);
    formData.append("amount", data.amount);
    formData.append("discount", data.discount ? data.discount : 0);
    formData.append("paid", data.paid);
    formData.append("description", data.description);
    formData.append("prescription", data.prescription);
    formData.append("date", data.date);
    formData.append("power", data.power);
    formData.append("session_number", data.session_number);
    if (isAdd) {
      for (const file of data?.attachment ?? []) {
        const blob = new Blob([file]);
        formData.append("attachments[]", blob);
      }
    }
    if (isAdd) {
      setOnRemoveFiles(true);
      dispatch(Operation.create(formData, _SearchBody)).then(() => {
        dispatch(resetForm());
        reset();
        setTimeout(() => {
          setOnRemoveFiles(false);
        }, 100);
      });
    } else {
      if (data?.attachment?.length > 0) {
        for (const file of data.attachment) {
          const blob = new Blob([file]);
          uploadFormData.append("attachment", blob);
        }
        dispatch(Operation.uploadAttachment(data.id, uploadFormData)).then(
          () => {
            setOnRemoveFiles(true);
            dispatch(Operation.update(data.id, formData, _SearchBody)).then(
              (x) => {
                setTimeout(() => {
                  setOnRemoveFiles(false);
                }, 100);
              }
            );
          }
        );
      } else {
        setOnRemoveFiles(true);
        dispatch(Operation.update(data.id, formData, _SearchBody)).then((x) => {
          setTimeout(() => {
            setOnRemoveFiles(false);
          }, 100);
        });
      }
    }
  };

  useEffect(() => {
    reset({ ..._Operation.form });
  }, [reset, _Operation.form]);

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
  }, [dispatch]);

  return (
    <Box sx={{ mb: 5 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {" "}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="operation_type_id"
                  control={control}
                  render={({ field }) => (
                    <SelectOperationType
                      {...field}
                      required
                      onChange={(e, newValue) => {
                        setValue(
                          "operation_type_id",
                          newValue ? newValue.id : null
                        );
                        setValue("amount", newValue ? newValue.price : "");
                        setValue("discount", newValue ? newValue.discount : "");
                      }}
                      value={
                        _OperationType?.filter((x) => x.id == field.value)[0] ??
                        null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="user_id"
                  control={control}
                  render={({ field }) => (
                    <SelectDoctor
                      {...field}
                      required
                      onChange={(e, newValue) => {
                        setValue("user_id", newValue ? newValue.id : null);
                      }}
                      value={
                        _user?.filter((x) => x.id == field.value)[0] ?? null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="power"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      label="الطاقة"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="session_number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      label="رقم الجسلة"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="YYYY-MM-DD"
                        label="التاريخ"
                        value={field.value}
                        onChange={(e, newValue) => {
                          setValue("date", dayjs(e).format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            size="small"
                            fullWidth
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "يوم/شهر/سنة",
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="المبلغ"
                        size="small"
                        required
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("amount", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                        helperText={`المبلغ الكلي ${
                          watch("amount") - watch("discount") || 0
                        }`}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="discount"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="الخصم"
                        size="small"
                        required
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("discount", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="paid"
                  control={control}
                  render={({ field: { onChange, ...renderProps } }) => {
                    return (
                      <NumericFormat
                        customInput={TextField}
                        label="المبلغ المدفوع"
                        size="small"
                        required
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              IQD
                            </InputAdornment>
                          ),
                        }}
                        {...renderProps}
                        onValueChange={(target) => {
                          onChange();
                          setValue("paid", target.floatValue);
                        }}
                        isNumericString
                        thousandSeparator
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      multiline
                      rows={4}
                      size="small"
                      label="الملاحظات"
                      fullWidth
                      {...field}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="prescription"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      multiline
                      rows={4}
                      size="small"
                      label="الوصفة"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="attachment"
              control={control}
              render={({ field }) => (
                <Box className="multiFilePond">
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ justifyContent: "center", paddingBottom: 2 }}
                  >
                    {!isAdd &&
                      _Operation.form.attachments.map((file, index) => (
                        <Box
                          key={index}
                          sx={{ position: "relative" }}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={() => handleMouseLeave(index)}
                        >
                          {selected == index && (
                            <IconButton
                              sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                              }}
                              aria-label="remove"
                              onClick={() =>
                                dispatch(
                                  Operation.deleteAttachmentById(
                                    file.id,
                                    _Operation.form.id
                                  )
                                )
                              }
                              onMouseEnter={() => setHoveredColor(true)}
                              onMouseLeave={() => setHoveredColor(false)}
                            >
                              <BsFillXCircleFill
                                size={25}
                                color={hoveredColor ? "#D32F2F" : ""}
                                style={{
                                  border: "1px solid #7B7B7B",
                                  borderRadius: "50%",
                                }}
                              />
                            </IconButton>
                          )}
                          <Box
                            component="img"
                            src={file.path}
                            sx={{ width: 200 }}
                          />
                        </Box>
                      ))}
                  </Stack>
                  {onRemoveFiles ? null : (
                    <FilePondInput allowMultiple={isAdd} field={field} />
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"start"}>
            <Stack direction={"row"} spacing={2}>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{ mr: 2, px: 8 }}
                startIcon={<AiOutlineSave />}
                endIcon={loading && <CircularProgress size={18} />}
                disabled={loading}
              >
                {isAdd ? "أضافة" : "حفظ"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOnRemoveFiles(true);
                  reset();
                  dispatch(resetForm());
                  setTimeout(() => {
                    setOnRemoveFiles(false);
                  }, 100);
                }}
                sx={{ mr: 2, px: 8 }}
                startIcon={<BiSolidEraser />}
              >
                تفريغ الحقول
              </Button>
              {/* {_Operation.form.operation_type_id && (
                <Badge
                  badgeContent=" "
                  color="error"
                  invisible={!_Operation.form.dental_implement}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      dispatch(DentalImplant.getById(_OperationId))
                    }
                    sx={{ mr: 2, px: 8 }}
                    startIcon={<TbDental />}
                  >
                    الزراعة
                  </Button>
                </Badge>
              )} */}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default OperationForm;
