import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./Column";
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setSearchBody,
  waitingList,
} from "../../../../app/slices/waitingListSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TimeDialog from "./timeDialog";

const Container = styled("div")`
  display: flex;
  background-color: ${(props) =>
    props.isDraggingOver ? "#639ee2" : "inherit"};
`;

const WaitingListData = () => {
  const dispatch = useDispatch();

  const _WaitingListTypes = useSelector(
    (state) => state.waitingList.waitingListData
  );
  const loading = useSelector((state) => state.waitingList.loading);
  const _SearchBody = useSelector((state) => state.waitingList.searchBody);
  const _WaitingList = useSelector(
    (state) => state.waitingList.timeData.dialog
  );

  const [starter, setStarter] = useState([]);

  useEffect(() => {
    setStarter(_WaitingListTypes?.data);
    console.log(_WaitingListTypes?.data);
  }, [_WaitingListTypes.data]);

  useEffect(() => {
    dispatch(waitingList.getAll(_SearchBody));
  }, [_SearchBody, dispatch]);

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = starter.columns[source.droppableId];
    const end = starter.columns[destination.droppableId];

    if (type === "column") {
      const newOrder = [...starter.columnOrder];
      newOrder.splice(source.index, 1);
      newOrder.splice(destination.index, 0, draggableId);
      setStarter({
        ...starter,
        columnOrder: newOrder,
      });
      return;
    }

    const startPatientIds = [...start.patient_id];
    const endPatientIds = [...end.patient_id];

    startPatientIds.splice(source.index, 1);
    endPatientIds.splice(destination.index, 0, draggableId);

    const waitingListObject = Object.values(starter.patients).find(
      (patient) => patient.patient_id === draggableId
    );

    const match = draggableId.match(/\d+/);
    const destinationColumnId = destination.droppableId.match(/\d+/);
    const sourceColumnId = source.droppableId.match(/\d+/);
    const isForward = destinationColumnId >= sourceColumnId ? true : false;

    switch (destination.droppableId) {
      case "column-1":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.waiting_treatment
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "waiting_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      case "column-2":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.in_treatment
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "in_treatment",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );
      // case "column-3":
      //   return isForward
      //     ? dispatch(
      //         waitingList.update(
      //           parseInt(match[0], 10),
      //           {
      //             current_status: "waiting_xray",
      //             date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      //           },
      //           _SearchBody
      //         )
      //       )
      //     : waitingListObject.waiting_xray
      //     ? dispatch(
      //         waitingList.update(
      //           parseInt(match[0], 10),
      //           {
      //             current_status: "waiting_xray",
      //           },
      //           _SearchBody
      //         )
      //       )
      //     : dispatch(
      //         waitingList.update(
      //           parseInt(match[0], 10),
      //           {
      //             current_status: "waiting_xray",
      //             date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      //           },
      //           _SearchBody
      //         )
      //       );
      // case "column-4":
      //   return isForward
      //     ? dispatch(
      //         waitingList.update(
      //           parseInt(match[0], 10),
      //           {
      //             current_status: "in_xray",
      //             date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      //           },
      //           _SearchBody
      //         )
      //       )
      //     : waitingListObject.in_xray
      //     ? dispatch(
      //         waitingList.update(
      //           parseInt(match[0], 10),
      //           {
      //             current_status: "in_xray",
      //           },
      //           _SearchBody
      //         )
      //       )
      //     : dispatch(
      //         waitingList.update(
      //           parseInt(match[0], 10),
      //           {
      //             current_status: "in_xray",
      //             date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      //           },
      //           _SearchBody
      //         )
      //       );
      case "column-5":
        return isForward
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            )
          : waitingListObject.leaving
          ? dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                },
                _SearchBody
              )
            )
          : dispatch(
              waitingList.update(
                parseInt(match[0], 10),
                {
                  current_status: "leaving",
                  date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                },
                _SearchBody
              )
            );

      default:
        return null;
    }
  };

  return (
    <Card sx={{ minHeight: "80vh", overflow: "auto", width: "100%" }}>
      {loading && <LinearProgress />}
      {_WaitingList && <TimeDialog />}
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="YYYY-MM-DD"
                label="التاريخ"
                value={_SearchBody.date}
                onChange={(e, newValue) => {
                  dispatch(
                    setSearchBody({
                      date: dayjs(e).format("YYYY-MM-DD"),
                    })
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    size="small"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "يوم/شهر/سنة",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            {!_WaitingListTypes.data.patients ? null : (
              <Box>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="all-column"
                    type="column"
                    direction="horizontal"
                    isDropDisabled
                  >
                    {(provided, snapshot) => (
                      <Container
                        isDraggingOver={snapshot.isDraggingOver}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          borderRadius: 3,
                        }}
                      >
                        <Grid container spacing={2}>
                          {starter.columnOrder?.map((columnId, index) => {
                            const column = starter?.columns[columnId];
                            const patients = column.patient_id?.map(
                              (patient_id) => starter.patients[patient_id]
                            );
                            return (
                              <Grid key={column.id} item xs={6} md={2.4}>
                                <Column
                                  index={index}
                                  key={column.id}
                                  column={column}
                                  patients={patients}
                                />
                              </Grid>
                            );
                          })}
                          {provided.placeholder}
                        </Grid>
                      </Container>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WaitingListData;
