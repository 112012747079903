import React from "react";
import styled from "@emotion/styled";
import Patient from "./patient";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Divider, Stack, Typography } from "@mui/material";
import { FaPeopleGroup, FaUserDoctor, FaXRay } from "react-icons/fa6";
import { GiEntryDoor } from "react-icons/gi";

const Container = styled("div")`
  margin: 8px;
  border-radius: 2px;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  width: 230px;
  background: white;
`;
const Title = styled("h3")`
  padding: 8px;
`;

const TaskList = styled("div")`
  padding: 8px;
  flex-grow: 1;
  min-height: 100px;
  transition: background-color ease 0.2s;
  background-color: ${(props) => (props.isDraggingOver ? "#0094D0" : "white")};
`;
const Column = ({ patients, column, index }) => {
  return (
    <Draggable
      draggableId={column.id}
      index={index}
      type="column"
      isDragDisabled
    >
      {(provided) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor:
                column.id === "column-1" || column.id === "column-3"
                  ? "#bdbdbd"
                  : column.id === "column-5"
                  ? "#81c784"
                  : "#c2eafc",
            }}
          >
            <Title>
              {column.title === "انتظار الفحص" ? (
                <Stack spacing={1} direction="row" alignItems="center">
                  <FaPeopleGroup size={30} />
                  <Typography variant="h4">{column.title}</Typography>
                </Stack>
              ) : column.title === "داخل غرفة الفحص" ? (
                <Stack spacing={1} direction="row" alignItems="center">
                  <FaUserDoctor size={30} />
                  <Typography variant="h4">{column.title}</Typography>
                </Stack>
              ) : (
                // : column.title === "انتظار الاشعة" ? (
                //   <Stack spacing={1} direction="row" alignItems="center">
                //     <FaPeopleGroup size={30} />
                //     <Typography variant="h4">{column.title}</Typography>
                //   </Stack>
                // ) : column.title === "داخل غرفة الاشعة" ? (
                //   <Stack spacing={1} direction="row" alignItems="center">
                //     <FaXRay size={30} />
                //     <Typography variant="h4">{column.title}</Typography>
                //   </Stack>
                // )
                <Stack spacing={1} direction="row" alignItems="center">
                  <GiEntryDoor size={30} />
                  <Typography variant="h4">{column.title}</Typography>
                </Stack>
              )}{" "}
            </Title>
          </Stack>
          <Divider />
          <Droppable droppableId={column.id} type="task">
            {(provided, snapshot) => (
              <TaskList
                isDraggingOver={snapshot.isDraggingOver}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {patients?.map((patient, index) => (
                  <Patient
                    key={patient?.patient_id}
                    patient={patient}
                    index={index}
                  />
                ))}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
