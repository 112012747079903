import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Reports, setSearchBody } from "../../../../../app/slices/reportsSlice";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { MdOutlineClear } from "react-icons/md";
import { SelectSection } from "../../../../../components/Selectors/SelectSection";
import { SelectOperationType } from "../../../../../components/Selectors/SelectOperationType";

const OperationTable = () => {
  const [search, setSearch] = useState({
    search: "",
    start_date: dayjs().format("YYYY-MM-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
    section_id: null,
    operation_type_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const dispatch = useDispatch();

  const _Reports = useSelector((state) => state.reports.operation);
  const loading = useSelector((state) => state.reports.loading);
  const _SearchBody = useSelector((state) => state.reports.searchBody);
  const _OperationType = useSelector(
    (state) => state.operationType.operationTypes.data
  );
  const _Section = useSelector((state) => state.section.sections.data);
  const reportTypes = useSelector((state) => state.reports.reportTypes);

  const columns = [
    {
      field: "id",
      headerName: "رقم الجلسة",
      flex: 1,
    },
    {
      field: "operation_type_name",
      headerName: "نوع الجلسة",
      flex: 1,
    },
    {
      field: "patient_name",
      headerName: "اسم المراجع",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ (IQD)",
      flex: 1,
      renderCell: (params) => (
        <>{parseInt(params.row.amount)?.toLocaleString()}</>
      ),
    },
    {
      field: "paid",
      headerName: "المبلغ المدفوع (IQD)",
      flex: 1,
      renderCell: (params) => (
        <>{parseInt(params.row.paid)?.toLocaleString()}</>
      ),
    },
    {
      field: "remaining",
      headerName: "المبلغ المتبقي (IQD)",
      flex: 1,
      renderCell: (params) => (
        <>{parseInt(params.row.remaining)?.toLocaleString()}</>
      ),
    },
    {
      field: "user",
      headerName: "اسم المستخدم",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => dayjs(params.row.date).format("YYYY-MM-DD"),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Reports.operation(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, _SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        start_date: search.start_date,
        end_date: search.end_date,
        section_id: search.section_id ?? "",
        operation_type_id: search.operation_type_id ?? "",
      })
    );
  }, [dispatch, search, reportTypes]);

  const customToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: "تقرير الخدمات | مركز اوبين لطب الاسنان والتجميل",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Card>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={3.5}>
            <SelectOperationType
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  operation_type_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _OperationType?.filter(
                  (x) => x.id === search.operation_type_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3.5}>
            <SelectSection
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  section_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _Section?.filter((x) => x.id === search.section_id)[0]
                  ? _Section?.filter((x) => x.id === search.section_id)[0]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    start_date: dayjs().format("YYYY-MM-01"),
                    end_date: dayjs().format("YYYY-MM-DD"),
                    section_id: null,
                    operation_type_id: null,
                  });
                  // setPageSize(15)
                  // setCurrentPage(0)
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Reports.data ? (
                <DataGrid
                  rows={_Reports.data}
                  columns={columns}
                  rowCount={_Reports.total}
                  loading={loading}
                  rowHeight={40}
                  paginationMode="client"
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  pageSize={pageSize}
                  page={currentPage}
                  pagination
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Toolbar: customToolbar,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OperationTable;
